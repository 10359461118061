<template>
	<div>
		<ddcard class="box-card" :title="$t('dashboard.left.stats-orders-header')" v-loading="isLoading">
						<div slot="title">
				<svg-icon icon-class="b" class="dashboard-icon-card" />
				<span class="dd-title">{{ $t('dashboard.left.stats-orders-header') }}</span>
			</div>
			<el-row :gutter="20">
				<el-col :xs="24" :sm="16" :md="14" :lg="16" :xl="18">
					<router-link to="/rapoarte/raport-comenzi" class="redirect-chart">
						<el-button>{{$t('dashboard.left.stats-more')}}</el-button>
					</router-link>
					<line-chart class="orders-chart" :options="chartOptions" :chartData="reactiveChart"></line-chart>
				</el-col>

				<el-col :xs="24" :sm="8" :md="10" :lg="8" :xl="6" class="stats-col">
					<panel-group></panel-group>
				</el-col>
			</el-row>

		</ddcard>
	</div>
</template>

<script>
	const ddcard = () => import('@/components/DDCard');
	const lineChart = () =>
		import('@/components/Charts/line');
	const PanelGroup = () => import('./PanelGroup');

	import {
		mapState,
		mapGetters
	} from 'vuex';
	export default {
		components: {
			PanelGroup,
			ddcard,
			'line-chart': lineChart,
		},
		data() {
			return {
				list: null,
				chartOptions: {
					height: '500px',
					scales: {
						yAxes: [{
							ticks: {
								beginAtZero: true,
								min: 0,
								stepSize: 1
							}
						}]
					}
				}
			};
		},
		computed: {
			...mapState({
				isLoading: (state) => state.dashboard.compound.loading,
				loaded: (state) => state.dashboard.compound.loaded,
			}),
			...mapGetters({
				ordersChartDays: 'dashboard/ordersChartDays',
				ordersChartFinished: 'dashboard/ordersChartFinished',
				ordersChartCanceled: 'dashboard/ordersChartCanceled',
				ordersChartStorno: 'dashboard/ordersChartStorno'
			}),
			reactiveChart() {
				const chart = {
					labels: this.ordersChartDays,
					datasets: [{
						label: this.$t('dashboard.left.stats-orders-finished'),
						data: this.ordersChartFinished,
						backgroundColor: [
							'rgba(64, 201, 198, 0.4)',
						],
						borderColor: [
							'rgba(64, 201, 198)',
						],
						borderWidth: 1
					}, {
						label: this.$t('dashboard.left.stats-orders-canceled'),
						data: this.ordersChartCanceled,
						backgroundColor: [
							'rgb(244, 81, 108, 0.4)',
						],
						borderColor: [
							'rgb(244, 81, 108)',
						],
						borderWidth: 1
					}, {
						label: this.$t('dashboard.left.stats-orders-storno'),
						data: this.ordersChartStorno,
						backgroundColor: [
							'rgb(144, 147, 153, 0.4)',
						],
						borderColor: [
							'rgb(144, 147, 153)',
						],
						borderWidth: 1
					}]
				};

				return chart;
			}
		},
		methods: {

		}
	};
</script>